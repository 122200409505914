<template lang="pug">
    section#cards
        .wrapper
            p.title Estamos aqui para te ajudar!
            p.subTitle Veja abaixo qual tipo de informação você está procurando.
            ul
                li
                    .img
                        img(:src="require('@images/paginas/contato/icone-fale-conosco.png')")
                    router-link(:to="{ path: '/pt-br/fale-conosco' }") Fale Conosco
                    p Informações, problemas com produtos/serviços e elogios
                li
                    .img
                        img(:src="require('@images/paginas/contato/icone-onde-encontrar.png')")
                    router-link(:to="{ path: '/onde-encontrar' }") Onde encontrar
                    p Onde encontrar produtos, representantes, unidades
                li
                    .img
                        img(:src="require('@images/paginas/contato/icone-canal-de-etica.png')")
                    a(href="https://www.contatoseguro.com.br/coamoagroindustrial" target="_blank") Canal de Ética
                    p Comunicação de violação ao 
                        |Código de Ética e Conduta Coamo
</template>

<script>
export default {
	name: "section-cards",
}
</script>

<style lang="stylus" scoped src="./Cards.styl"></style>
